import React, { Component } from 'react';

import { Image, Button } from 'library';
import { UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: isMobile ? false : true,
        };
    }

    render() {
        let checker = new UserValidator();
        let is_logged_in = checker.logged_id();
        let is_staff = checker.is_staff();

        let navbar_collapse = '';
        let navbar_toggle = null;

        let items = [];
        /*
        items.push(
            <li className="nav-item">
                <a
                    className="nav-link"
                    href="https://discord.gg/cYvcWSUxTn"
                    target="_blank"
                    style={{
                        color: 'white',
                        marginRight: '10px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        backgroundColor: '#7389de',
                        borderRadius: '3px',
                    }}
                >
                    <img
                        src="https://mapquest-public.s3.amazonaws.com/21_01_05_20_04_13_Discord-logo.png"
                        style={{
                            width: '25px',
                            marginRight: '10px',
                        }}
                    ></img>
                    Join our Discord Server
                </a>
            </li>,
        );
        */

        if (!is_logged_in) {
            items.push(
                <li className="nav-item">
                    <a className="nav-link" style={{ padding: '0px' }}>
                        <Button
                            type="dark"
                            href="/signup/"
                            style={{ marginRight: '10px' }}
                        >
                            Customize To Your Campaign
                        </Button>
                    </a>
                </li>,
            );
        }

        if (is_staff) {
            navbar_collapse = 'collapse navbar-collapse';
            navbar_toggle = (
                <button
                    class="navbar-toggler"
                    type="button"
                    onClick={() => this.setState({ show: !this.state.show })}
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
            );
            // Add staff routes to header
            items.push(
                <li className="nav-item">
                    <a className="nav-link" href="/maps">
                        Maps
                    </a>
                </li>,
                <li className="nav-item">
                    <a className="nav-link" href="/admin/">
                        Admin
                    </a>
                </li>,
            );
        }

        if (is_logged_in) {
            // Add authed routes to header
            items.push(
                <li className="nav-item">
                    <a className="nav-link" href="/logout/">
                        Logout
                    </a>
                </li>,
            );
        }

        return (
            <nav
                className="navbar navbar-expand-lg bg-body-tertiary"
                style={{ zIndex: 100 }}
            >
                <a className="navbar-brand" href="/" style={{ padding: '0px' }}>
                    <Image
                        src="https://mapquest-public.s3.amazonaws.com/24_07_13_23_16_42_icon_monster.png"
                        style={{ maxHeight: '44px' }}
                    />
                </a>
                {navbar_toggle}
                <div
                    class={`${navbar_collapse} ${
                        this.state.show ? 'show' : ''
                    }`}
                    style={{
                        maxHeight: `${
                            navbar_toggle
                                ? this.state.show
                                    ? '250px'
                                    : '0px'
                                : 'auto'
                        }`,
                        transition: 'max-height .35s ease',
                        overflow: 'hidden',
                        display: 'flex',
                        flexGrow: '1',
                    }}
                >
                    <ul className="navbar-nav" style={{ marginLeft: 'auto' }}>
                        {items}
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Header;
