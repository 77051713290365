import { TextInput, Form, Button } from 'library';
import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

class LocationSelect extends Component {
    render() {
        return (
            <a
                onClick={() =>
                    this.props.pan_to_location(this.props.location.location)
                }
                style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}
            >
                {this.props.location.location.name}
            </a>
        );
    }
}

export default class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            search_text: '',
            found_locations_by_name: [],
            found_locations_by_content: [],
            height: 0,
        };

        this.search = this.search.bind(this);
        this.clear = this.clear.bind(this);
    }

    componentDidMount() {
        // Set initial size
        this.calculate_size();
        // Update size
        window.addEventListener('resize', this.calculate_size);
    }

    calculate_size = () => {
        this.setState({
            height: $(window).height() - HEADER_HEIGHT,
        });
    };

    search(name, data) {
        let found_locations_by_name = [];
        let found_locations_by_content = [];
        let search_text = data['search_text'];

        if (!search_text || search_text == '') {
            this.setState({
                search_text: '',
                found_locations_by_content: [],
                found_locations_by_name: [],
            });
            return false;
        }

        let regex = new RegExp(
            '(?<!<[^>]*?)' + search_text + '(?![^<]*?>)',
            'gi',
        );
        let locations = this.props.locations;
        for (var location of locations) {
            let name = location.name;

            if (name.toLowerCase().includes(search_text.toLowerCase())) {
                found_locations_by_name.push(location);

                if (found_locations_by_name.length > 20) {
                    break;
                }

                continue;
            }
        }

        for (var location of locations) {
            let found = false;
            if (location.wiki_page_content) {
                for (var section of location.wiki_page_content) {
                    if (section.name == 'Appendix') {
                        continue;
                    }

                    let content = section.clean_text;
                    if (content.search(regex) > -1) {
                        let found_index = content
                            .toLowerCase()
                            .indexOf(search_text.toLowerCase());
                        let start_index = found_index - 150;
                        let end_index = found_index + 150;

                        if (start_index < 0) {
                            start_index = 0;
                        }
                        if (end_index > content.length) {
                            end_index = content.length;
                        }

                        while (start_index > 0 && content[start_index] != ' ') {
                            start_index -= 1;
                        }
                        while (
                            end_index > content.length &&
                            content[end_index] != ' '
                        ) {
                            start_index += 1;
                        }

                        let sentence = content.substring(
                            start_index,
                            end_index,
                        );

                        sentence = sentence.replace(
                            regex,
                            (match) =>
                                `<mark style='padding:0px;font-weight:500;'>${match}</mark>`,
                        );

                        let html_end = sentence.indexOf('>');
                        let html_start = sentence.indexOf('<');
                        if (html_end < html_start) {
                            sentence = sentence.substring(
                                html_end + 1,
                                sentence.length,
                            );
                        }

                        found_locations_by_content.push({
                            location: location,
                            section_name: section.name,
                            content: sentence,
                        });
                        found = true;
                    }

                    if (found) {
                        break;
                    }
                }

                if (
                    found_locations_by_name.length +
                        found_locations_by_content.length >
                    20
                ) {
                    break;
                }
            }
        }

        this.setState({
            search_text: search_text,
            found_locations_by_name: found_locations_by_name,
            found_locations_by_content: found_locations_by_content,
        });
    }

    clear() {
        this.setState({
            search_text: '',
            found_locations_by_content: [],
            found_locations_by_name: [],
            focused: false,
        });
    }

    focus = () => {
        if (this.search_input) {
            this.search_input.focus();
        }
        this.setState({ focused: true });
    };

    render() {
        let found_locations_by_name = [];
        for (var location of this.state.found_locations_by_name) {
            found_locations_by_name.push(
                <div className="simple-card" style={{ padding: '6px 12px' }}>
                    <h6 style={{ wordWrap: 'break-word', marginBottom: '0px' }}>
                        <LocationSelect
                            location={{ location: location }}
                            pan_to_location={this.props.pan_to_location}
                        />
                    </h6>
                </div>,
            );
        }

        let found_locations_by_content = [];
        for (var location of this.state.found_locations_by_content) {
            found_locations_by_content.push(
                <div className="simple-card" style={{ padding: '6px 12px' }}>
                    <h6 style={{ wordWrap: 'break-word' }}>
                        <LocationSelect
                            location={location}
                            pan_to_location={this.props.pan_to_location}
                        />
                    </h6>
                    <div>{location.section_name}</div>
                    <div
                        style={{ fontSize: '.8em' }}
                        dangerouslySetInnerHTML={{ __html: location.content }}
                    ></div>
                </div>,
            );
        }

        let clear_button = (
            <Button
                className="round-btn right-floater"
                type="danger"
                onClick={this.clear}
            >
                <i class="bi bi-x"></i>
            </Button>
        );

        let results = null;
        if (
            found_locations_by_name.length + found_locations_by_content.length >
            0
        ) {
            results = (
                <div
                    className="simple-card-container"
                    style={{
                        position: 'relative',
                        top: '-4px',
                        zIndex: 9,
                        maxHeight: `${this.state.height - 50}px`,
                        overflow: 'auto',
                        marginBottom: '0px',
                    }}
                >
                    {found_locations_by_name}
                    {found_locations_by_content}
                </div>
            );
        }

        let search_button = (
            <Button
                className="round-btn"
                style={{
                    padding: '6px 11px',
                    fontSize: '20px',
                    position: 'absolute',
                    top: '-3px',
                    left: '-3px',
                    transition: 'opacity .2s',
                    zIndex: 11,
                    opacity: this.state.focused ? 0 : 1,
                }}
                type="primary"
                onClick={this.focus}
            >
                <i class="bi bi-search"></i>
            </Button>
        );

        let search_style = {
            transition: 'width .2s',
            width: this.state.focused ? '300px' : '0px',
            overflow: 'hidden',
        };

        return (
            <div
                style={{
                    position: 'absolute',
                    left: '10px',
                    top: '10px',
                    width: '300px',
                    height: '50px',
                }}
            >
                {search_button}
                <div style={search_style}>
                    <div style={{ position: 'relative', zIndex: 10 }}>
                        <Form
                            auto_set_global_state={true}
                            set_global_state={this.search}
                            defaults={{ search_text: this.state.search_text }}
                            uses_global_dict={true}
                        >
                            <TextInput
                                name="search_text"
                                autocomplete={'off'}
                                set_ref={(input) => {
                                    this.search_input = input;
                                }}
                                //onBlur={() => this.setState({ focused: false })}
                            />
                        </Form>
                        {clear_button}
                    </div>
                    {results}
                </div>
            </div>
        );
    }
}
